.productpageCategory {
    /* height: 100vh; */
    padding: 20px;
    color: white;
    /* position: sticky; */
    /* top: 160px; */
    display: flex;
    flex-direction: column;
    padding-top: 70px !important;
}

.productes {
    flex: 1;
}

.productImage {
    width: 100%;
    max-width: 100%;
    height: auto !important;
    max-height: 100%;
    object-fit: cover;
}

.textCategrory {
    transition: 0.5s;
}

.textCategrory:hover {
    transition: 0.5s;
    color: rgba(2, 15, 61, 0.893);
}

.brendTitle {
    color: rgba(2, 15, 61, 0.893);

}


.SearchImageNotDate {
    width: 100%;
    display: flex;
}

.notProduct {
    margin: auto;
}

@media only screen and (max-width: 1405px) {
    .productpageCategory {
        display: none !important;
    }
}