.parallax {
    height: 600px;
    background-color: rgba(0, 0, 0, 0.315);
}

.parallaxText {
    position: absolute;
    width: 800px;
    top: 10%;
    left: 25% !important;
}

.parallaxText h2 {
    color: #FCC12C;
}

@media only screen and (max-width: 1405px) {
    .parallaxText {
        top: 10%;
        left: 22%;
        width: 700px;

    }
}

@media only screen and (max-width: 1200px) {
    .parallaxText {
        top: 10%;
        left: 18%;
        width: 600px !important;
    }
}

@media only screen and (max-width: 970px) {
    .parallaxText {
        top: 10%;
        left: 15%;
        width: 500px !important;

    }
}

@media only screen and (max-width: 770px) {
    .parallax {
        height: 500px;
    }

    .parallaxText {
        top: 10%;
        left: 10%;
        width: 400px !important;
    }

}


@media only screen and (max-width: 610px) {
    .parallax {
        height: 400px;
    }

    .parallaxText {
        top: 10%;
        left: 3%;
        width: 300px !important;
    }
}

@media only screen and (max-width: 450px) {
    .parallaxText {
        top: 10%;
        left: 0%;
        width: 250px !important;
    }

    .parallaxText h3 {
        font-size: 20px;
    }
}

@media only screen and (max-width: 390px) {
    .parallax {
        height: 300px;
    }

    .parallaxText {
        top: 10%;
        left: 20% !important;
        width: 230px !important;
    }

    .parallaxText h3 {
        font-size: 18px;
    }
}