.slider {
    display: flex;
    height: auto;
    margin: auto;
    overflow: hidden;
    align-items: center;
}

.slider::before {
    position: absolute;
    z-index: 1;
    left: 0;
    content: "";
    /* width: 23.950%; */
    height: 110px;
}

.slider::after {
    position: absolute;
    right: 0;
    content: "";
    /* width: 23.950%; */
    height: 110px;
}

.slider .slide-track {
    display: flex;
    animation: scroll 60s linear infinite !important;
    -webkit-animation: scroll 20s linear infinite !important;
    width: calc(900px * 10) !important;
}

.slider .slide {
    display: flex;
}

.slider .slide img {
    width: 200px;
    height: 120px;
    padding: 10px;
    background-color: white;
    margin: 20px;
    object-fit: contain;
}

@keyframes scroll {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }

    50% {
        -webkit-transform: translateX(calc(-320px * 10));
        transform: translateX(calc(-320px * 10));
    }

    100% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}