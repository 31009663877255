/* @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@1,300&family=Roboto&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@1,300&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto&display=swap');

* {
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif !important;
}


body {
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden !important;
}

.outlet {
    min-height: 80vh;
}