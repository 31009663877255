.accordion-button:not(.collapsed) {
    background-color: white;
}

.accordion-button:focus {
    box-shadow: 0 0 0 0.25rem white !important;
    border-bottom: 1px solid black;
}

.companyTitle:hover {
    color: #FCC12C;
}

.needColor {
    color: #FCC12C;
}

.textCompany {
    color: rgb(2, 15, 61);
}

.companyText {
    color: #FCC12C;
}

.infoCounrtImg {
    /* width: 100%; */
    height: 400px;
    object-fit: contain;
}

@media only screen and (max-width: 1010px) {
    .infoOrder {
        order: 1;
    }
}


@media only screen and (max-width: 928px) {
    .infoOrder {
        order: 1;
    }
}

@media only screen and (max-width: 600px) {
    .companyText {
        margin-bottom: 5px !important;
    }
}