iframe {
    width: 100%;
    border-radius: 5px;
    height: 425px;
}

.newshop {
    height: 425px !important;
    object-fit: cover;
}

.ShopTwo {
    width: 635px;
    height: 425px !important;
    object-fit: cover;
}

.nameLocated {
    color: #FCC12C;
    font-weight: bold;
}

.locatedA {
    color: rgba(2, 15, 61, 0.893);
    transition: 0.3s;
}

.locatedA:hover {
    color: #FCC12C;
}

@media only screen and (max-width: 1401px) {
    iframe {
        height: 368px;
    }

    .newshop {
        height: 368px !important;
    }

    .ShopTwo {
        width: 845px;
        height: 360px !important;
    }
}

@media only screen and (max-width: 1200px) {
    iframe {
        height: 305px;
    }

    .newshop {
        height: 305px !important;
    }

    .ShopTwo {
        width: 845px;
        height: 360px !important;
    }
}

@media only screen and (max-width: 991px) {
    iframe {
        height: 425px;
    }

    .newshop {
        height: 425px !important;
    }
}

@media only screen and (max-width: 770px) {
    iframe {
        height: 350px;
    }

    .newshop {
        height: 350px !important;
    }

}

@media only screen and (max-width: 450px) {
    iframe {
        height: 290px;
    }
}

@media only screen and (max-width: 1002px) {
    .infoLocated {
        order: 1;
    }
}