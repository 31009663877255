.navbarmain {
    z-index: 99;
    position: fixed;
    top: 0;
    height: auto;
    width: 100%;
}

.navbarbg {
    backdrop-filter: blur(25px);
}

.logodiv {
    padding-left: 40px;
}

.logo {
    width: 150px;
}

.social {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 8px;
    border: 2px solid #FCC12C;
    transition: 0.5s ease;
}

.instagram {
    color: black;
    transition: 0.3s;
}

.social:hover .instagram {
    color: white !important;
}

.social:hover {
    background-color: rgba(2, 15, 61, 0.893) !important;
}

.telephone {
    color: rgba(2, 15, 61, 0.893);
}

.number:hover {
    color: #FCC12C;
    transition: 0.3s;
}

.phoneIcon {
    transition: 0.3s;
}

.number:hover .phoneIcon {
    color: #FCC12C !important;
}

.header {
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(2, 15, 61, 0.893);
}

li {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: black;
}

.navbar {
    margin-top: 10px;
}

.navbar li {
    padding-right: 25px;
}

.navbar li a {
    font-size: 25px;
    cursor: pointer;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: 0.5s;
    color: #FCC12C;
    text-decoration: none;
}

.dropDown {
    position: relative;

}

.dropdown-content {
    display: none;
    position: absolute;
    width: 230px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    top: 70px !important;
    z-index: 99;
    background-color: rgba(2, 15, 61, 0.893);
    margin: 0 5px;
    left: -10px;
    border-radius: 0 0 15px 15px;
    transition: 0.5s ease all !important;
    box-shadow: 22px 32px 56px #06000b;
}

.dropBlock {
    display: block;
    transition: 0.5s ease all !important;
}

.dropIcon {
    color: #FCC12C;
    transition: 0.5s !important;
}


.productNavigate {
    transition: 0.5s;
    line-height: 20px;
    margin: 10px;
    color: #FCC12C !important;
}

.productNavigate:hover {
    transition: 0.5s;
    color: white !important;
    background-size: 100% 1px;
}

.headerTitle {
    color: #FCC12C;
    font-weight: bold;
}

.headerTitle::after {
    content: "";
    width: 50px;
    height: 2px;
    background: #FCC12C;
    display: inline-block;
    margin: 0 0 10px 15px;
}

.headerTitle::before {
    content: "";
    width: 50px;
    height: 2px;
    background: #FCC12C;
    display: inline-block;
    margin: 0 15px 10px 0;
}

.navbar li a:hover {
    color: #FCC12C;
    border-bottom: 2px solid #FCC12C;
    padding-bottom: 10px;
}

.btnsearch {
    background-color: #FCC12C !important;
    outline: none !important;
    border-radius: 0 5px 5px 0;
}

.btnsearch:hover {
    background-color: #f2b008 !important;
}

.btnx {
    color: #FCC12C !important;
    margin-right: 20px;
}

.searchinput {
    outline: none;
    border-radius: 8px 0 0 8px;
    padding: 4px 5px;
    border: none;
}

@media only screen and (max-width: 1405px) {

    .navbar li {
        padding-right: 20px;
    }

    .navbar li a {
        font-size: 20px;
    }

    .searchinput {
        width: 150px;
    }


}

@media only screen and (max-width: 1200px) {

    .telephone {
        font-size: 20px !important;
        padding-left: 0px;
    }

    .navbar {
        margin: 0 0 !important;
    }

    .navbar li {
        padding-right: 15px;
    }

    .navbar li a {
        font-size: 16px;
    }

    .searchinput {
        width: 100px !important;
        height: 30px;
    }

    .btnsearch {
        font-size: 17px !important;
        height: 30px;
    }

    .iconsearch {
        margin-bottom: 20px !important;
        font-size: 20px !important;
    }

    .logo {
        width: 120px;
        margin-left: 0;
    }

    .clocknumber {
        padding-top: 5px;
    }

    .dropIcon {
        font-size: 25px !important;
    }
}

@media only screen and (max-width: 960px) {
    .header {
        padding: 0 10px !important;
    }

    .logodiv {
        text-align: center;
    }

    .logo {
        margin: auto;
        width: 150px;
    }

    .lefthide {
        right: -500px !important;
    }

    .dropdown-content {
        top: 22px !important;
        left: -300px !important;
        border-radius: 15px;
        background-color: rgba(0, 0, 0, 0.6);
        transition: 0.5s;
        overflow-y: scroll;
        max-height: 300px;
    }

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
    }

    .navbar {
        display: block !important;
        position: absolute;
        top: 140px;
        right: 0;
        width: 300px !important;
        backdrop-filter: blur(15px);
        height: 10000%;
        text-align: center;
        transition: 0.9s;
    }

    .navbar li {
        padding: 10px 0 !important;
        margin: 10px 35px !important;
        border-radius: 15px;
        background-color: rgba(0, 0, 0, 0.478);
        transition: 0.5s;
    }

    .navbar li a {
        color: #FCC12C !important;
        border-bottom: none !important;
        transition: 0.5s !important;
        font-size: 20px !important;

    }

    .navbar li:hover .dropIcon {
        color: white;
        transition: 0.5s !important;
    }

    .navbar li:hover {
        color: black !important;
        margin: 10px 35px;
        background-color: rgba(0, 0, 0, 0.655);

    }

    .navbar li:hover a {
        color: white !important;
    }

    .clock {
        display: none !important;
    }

    .number {
        color: #f2b008 !important;
    }

    .clocknumber {
        text-align: center !important;
        margin: auto !important;

    }

    .socialmedia {
        display: none;
    }

    .menubtn {
        display: block !important;
        margin-right: 20px;
        color: #f2b008 !important;
    }

    .btnx {
        display: block !important;
    }

    .inputs {
        display: flex;
        justify-content: center !important;
    }

    .searchinput {
        width: 200px !important;
        height: 35px;
    }

    .iconsearch {
        font-size: 20px !important;
    }

    .btnsearch {
        height: 35px;
    }
}

@media only screen and (max-width: 800px) {
    .header {
        padding: 0 5px !important;
    }

    .logo {
        margin: auto;
        width: 90px;
    }

    .number {
        font-size: 15px !important;
    }

    .navbar {
        top: 93px;
    }

    .searchinput {
        width: 200px !important;
        height: 25px;
    }

    .btnsearch {
        height: 25px;
    }

    .iconsearch {
        font-size: 15px !important;
    }
}

@media only screen and (max-width: 650px) {
    .dropBlock {
        display: none !important;
    }

    .dropIcon {
        display: none !important;
    }
}


@media only screen and (max-width: 600px) {
    .searchinput {
        width: 150px !important;
        height: 25px;
    }

    .btnsearch {
        height: 25px;
    }

    .iconsearch {
        font-size: 15px !important;
    }
}


@media only screen and (max-width: 450px) {
    .number {
        font-size: 10px !important;
    }

    .phoneIcon {
        padding-bottom: 3px !important;
    }

    .logo {
        margin: auto;
        width: 70px;
    }

    .menubtn,
    .btnx {
        font-size: 25px !important;
    }

    .navbar {
        top: 73px;
    }

    .searchinput {
        width: 100px !important;
        height: 20px;
    }

    .searchinput::placeholder {
        font-size: 10px !important;
    }

    .btnsearch {
        height: 20px;
        font-size: 10px !important;
    }

    .iconsearch {
        font-size: 10px !important;
    }
}