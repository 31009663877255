.infoPage {
    margin-top: 160px;
}

.infoCard {
    width: auto;
}

.caruselInfo {
    height: auto;
}

.infoCaruselImg {
    height: 100%;
    max-height: 500px;
}

.infoImg {
    width: 100% !important;
    height: 100%;
    max-height: 500px;
    object-fit: cover;
}

.infoZoom {
    display: flex;
    justify-content: center;
    align-items: center;
}

.infoZoomImg {
    max-width: 700px !important;
    max-height: 600px;
}

.downloadIcon {
    color: rgba(2, 15, 61, 0.893);
}

.downloadIcon:hover {
    color: #FCC12C;
}

.aboutProduct {
    color: #FCC12C;
}