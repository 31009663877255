.categoryCard {
    transition: 0.5s ease;
    height: auto;
}

.customers {
    justify-content: space-around;
    background-color: rgba(2, 15, 61, 0.893);
}

.customerNumber {
    font-size: 170px;
    color: #f6bd2f;
}

.customerText {
    color: #f6bd2f;
}

.mainImg {
    object-fit: cover;
    height: 250px;
}

.categoryText {
    color: #FCC12C;
}

.categoryCard:hover {
    z-index: 1;
    box-shadow: 5px 5px 20px 10px rgba(2, 15, 61, 0.536) !important;
}

.categoryCard:hover .categoryText {
    color: rgba(2, 15, 61, 0.536) !important;
}

@media only screen and (max-width: 1460px) {
    .categoryText {
        font-size: 25px !important;
    }
}