.caruseldiv {
    position: relative;
}

.caruselImg {
    object-fit: cover !important;
}

.caruseltext {
    position: absolute;
    left: 32%;
    top: 80%;
    z-index: 10;
    color: #FCC12C;
    transform: translateY(-30%);
    background-color: rgba(2, 15, 61, 0.536);
    border-radius: 50px 0 50px 0;
    width: 550px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    border: 2px solid black;
}


.slick-dots li button {
    width: 60px !important;
    height: 5px !important;
    padding-right: 20px;
    border-radius: 10px !important;
    
}

.slick-dots li button::after {
    width: 60px !important;
    height: 5px !important;
    border-radius: 10px !important;
}

.slick-dots li {
    width: 60px !important;
    height: 5px !important;
    padding-bottom: 20px !important;
}


.slick-dots li button:before {

    opacity: 0 !important;
}


@media only screen and (max-width: 1405px) {
    .caruseltext {
        left: 25%;
    }
}

@media only screen and (max-width: 916px) {
    .caruseltext {
        /* left: 20%; */
        width: 400px;
        height: 80px;
    }

    .caruseltext h1 {
        font-size: 25px !important;
    }
}

@media only screen and (max-width: 650px) {
    .caruselImg {
        height: 600px !important;
    }

    .caruseltext {
        left: 20%;
        width: 350px;
        height: 80px;
        padding-top: 15px;
    }

    .caruseltext h1 {
        font-size: 20px !important;
    }
}

@media only screen and (max-width: 450px) {
    .caruselImg {
        height: 500px !important;
    }

    .caruseltext {
        left: 20% !important;
        width: 200px;
        height: 50px;
        padding-top: 20px;
    }

    .caruseltext h1 {
        font-size: 10px !important;
    }
}