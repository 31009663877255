.footerbg {
    /* background: url("https://mirlar.uz/wp-content/uploads/2019/07/amb-03-britania.jpg") no-repeat 0,
        #535454; */
    background-blend-mode: multiply;
    background: url(../../img/footerImg.png)no-repeat 0, #535454;
    background-size: 100%;
    object-fit: cover !important;
}

.footerTitle {
    color: #FCC12C;
    font-weight: bold;
}

.direction {
    width: 220px;
}

.footerIcon {
    color: #FCC12C;
    font-size: 20px;
    padding-bottom: 3px;
}

.foterText a:hover {
    padding-left: 10px;
    transition: 0.5s ease;
}

.footerCategory p {
    margin-bottom: 0 !important;
    cursor: pointer;
}

.footerCategory p:hover {
    padding-left: 10px;
    transition: 0.5s ease;
}

.footerPhone a {
    text-decoration: none;
    color: white !important;
    transition: 0.5s ease;
}

.footerPhone a:hover {
    color: #FCC12C !important;
}


@media only screen and (max-width: 800px) {
    .foterAbout {
        flex-direction: column;
        justify-content: center !important;
        align-items: center !important;
    }

    .direction {
        width: 160px;
        padding-bottom: 25px;
        margin: auto !important;
    }

    .footerbg {
        object-fit: contain !important;
        background-size: 100% 100%;
    }
}