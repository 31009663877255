.toPhone::before,
.toPhone::after {
    content: "";
    position: absolute;
    border: 3px solid #FCC12C;
    left: -20px;
    right: -20px;
    top: -20px;
    bottom: -20px;
    border-radius: 50%;
    animation: toPhone 1.8s linear infinite;
}

.toPhone::after {
    animation-delay: .5s;
}

@keyframes toPhone {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: scale(1.2);
        opacity: 0;
    }
}

.toPhone {
    position: fixed;
    font-size: 40px;
    z-index: 99;
    top: 600px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #FCC12C;
    border: 2px solid #daa41a;
    color: black !important;
    transition: 0.5s;
    animation: Phone 1.9s ease-in-out infinite;
}

.toPhone:hover {
    font-size: 50px;
    background-color: white;
}

@keyframes Phone {
    0%, 100% {
        transform: rotate(-15deg);
    }

    50% {
        transform: rotate(10deg);
    }

}